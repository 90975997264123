import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import tomato from "../images/tomato.jpg"
import { Link } from 'react-router-dom';
import { FaBasketShopping } from "react-icons/fa6";

const TopSellingComponent = () => {
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    dot:false,
    responsive: {
      0: {
        items: 3
      },
      600: {
        items: 4
      },
      1000: {
        items: 4
      }
    }
  };

  return (
    <div className='container top-selling-section'>
    <div className='section-title'>
        <h3>Top selling vegitables</h3>
    </div>
    <OwlCarousel className="owl-theme" {...options}>
      <div className="item">
      <Link to="/product">
            <img src={tomato}></img>
            <h6 className='product-title'>Tomato</h6>
            <div className='product-card-info'>
            <price>20₹/kg</price>
            <basket><FaBasketShopping /></basket>
            </div>
            
            </Link>
      </div>
      <div className="item">
      <Link to="/product">
            <img src="https://5.imimg.com/data5/LE/WP/CF/ANDROID-83273649/potato-jpg.jpg"></img>
            <h6 className='product-title'>Tomato</h6>
            <div className='product-card-info'>
            <price>20₹/kg</price>
            <basket><FaBasketShopping /></basket>
            </div>
            
            </Link>
      </div>
      <div className="item">
      <Link to="/product">
            <img src={tomato}></img>
            <h6 className='product-title'>Tomato</h6>
            <div className='product-card-info'>
            <price>20₹/kg</price>
            <basket><FaBasketShopping /></basket>
            </div>
            
            </Link>
      </div>
      <div className="item">
      <Link to="/product">
            <img src="https://5.imimg.com/data5/LE/WP/CF/ANDROID-83273649/potato-jpg.jpg"></img>
            <h6 className='product-title'>Tomato</h6>
            <div className='product-card-info'>
            <price>20₹/kg</price>
            <basket><FaBasketShopping /></basket>
            </div>
            
            </Link>
      </div>
      <div className="item">
      <Link to="/product">
            <img src={tomato}></img>
            <time>2h Ago</time>
            <h6 className='product-title'>Tomato</h6>
            <div className='product-card-info'>
            <price>20₹/kg</price>
            <basket><FaBasketShopping /></basket>
            </div>
            
            </Link>
      </div>
      <div className="item">
      <Link to="/product">
            <img src={tomato}></img>
            <h6 className='product-title'>Tomato</h6>
            <div className='product-card-info'>
            <price>20₹/kg</price>
            <basket><FaBasketShopping /></basket>
            </div>
            
            </Link>
      </div>
      
    </OwlCarousel>
    </div>
  );
};

export default TopSellingComponent;