// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
import Header from './components/header';
import Footer from './components/footer';
import Homeapp from './pages/homeapp';
import Product from './pages/product';
 // We'll create this next

function App() {
  return (
    <Router>
   
      <div className="App">
        <Routes>
          <Route path="/" element={<Homeapp />} />
          {/* <Route path="/homeapp" element={<Homeapp />} /> */}
          <Route path="/product" element={<Product />} />

        </Routes>
      </div>
    
    </Router>
  );
}

export default App;