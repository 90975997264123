import React, { useState, useEffect, useRef } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { IoSearch } from "react-icons/io5";
const SearchInput = ({ products = [], onFilter }) => {


  return (
    <div className="search-container container">
      
        <div className="search-input-container">
          <input
       
            type="text"
          
            placeholder="Search products..."
            className="search-input"
          />
          <button  className="search-button">
          <IoSearch />
          </button>
        </div>
   
    </div>
  );
};

export default SearchInput;