import React from 'react'
import tomato from "../images/tomato.jpg"
import { Link } from 'react-router-dom'
import { FaBasketShopping, FaStar } from "react-icons/fa6";

const Vegitables = () => {
  return (
    <div className='vegitables'>
    <div className='container'>
        <div className='section-title'>
            <h3>Vegitables</h3>
        </div>
    <div className='vegitables-verticle-outer'>
    <Link to="/product">
        <div className='vegitables-verticle-card'>
            <div className='veg-card-img'>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1WSTv8e5TZZNGJrfOELC2JV8ozcnfMbld8g&usqp=CAU" />
              <time>1h/ago</time>
            </div>
            <div className='veg-card-info'>
            <h6 className='product-title'>Tomato</h6>
            
            <price>20₹/kg</price>
            </div>
            <div className='veg-card-cart'>
                <rate><FaStar />4.5 (20k)</rate>
                <basket>Add To Cart<FaBasketShopping /></basket>
            </div>
        </div>
    </Link>

    <Link to="/product">
        <div className='vegitables-verticle-card'>
            <div className='veg-card-img'>
              <img src="https://5.imimg.com/data5/LE/WP/CF/ANDROID-83273649/potato-jpg.jpg" />
              <time>1h/ago</time>
            </div>
            <div className='veg-card-info'>
            <h6 className='product-title'>Tomato</h6>
            
            <price>20₹/kg</price>
            </div>
            <div className='veg-card-cart'>
                <rate><FaStar />4.5 (20k)</rate>
                <basket>Add To Cart<FaBasketShopping /></basket>
            </div>
        </div>
    </Link>

    <Link to="/product">
        <div className='vegitables-verticle-card'>
            <div className='veg-card-img'>
              <img src={tomato} />
              <time>1h/ago</time>
            </div>
            <div className='veg-card-info'>
            <h6 className='product-title'>Tomato</h6>
            
            <price>20₹/kg</price>
            </div>
            <div className='veg-card-cart'>
                <rate><FaStar />4.5 (20k)</rate>
                <basket>Add To Cart<FaBasketShopping /></basket>
            </div>
        </div>
    </Link>
    <Link to="/product">
        <div className='vegitables-verticle-card'>
            <div className='veg-card-img'>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1WSTv8e5TZZNGJrfOELC2JV8ozcnfMbld8g&usqp=CAU" />
              <time>1h/ago</time>
            </div>
            <div className='veg-card-info'>
            <h6 className='product-title'>Tomato</h6>
            
            <price>20₹/kg</price>
            </div>
            <div className='veg-card-cart'>
                <rate><FaStar />4.5 (20k)</rate>
                <basket>Add To Cart<FaBasketShopping /></basket>
            </div>
        </div>
    </Link>
    </div>
    </div>
      <div style={{margin: '93px'}}></div>
    </div>
  )
}

export default Vegitables
