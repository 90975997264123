import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { FaCircleUser } from "react-icons/fa6";
import SearchInput from '../components/search';
import TopSellingComponent from '../app_component/topSellingComponent';
import Vegitables from '../app_component/vegitables';
import App_footer from '../app_component/app_footer';

const Homeapp = () => {


  return (
    <>
      <header>
        <h1>Hello Roshan Maravi</h1>
        <Link to="/profile"><FaCircleUser /></Link>
      </header>
      <SearchInput  />
     <TopSellingComponent/>
     <Vegitables/>
     <App_footer/>
    </>
  )
}

export default Homeapp