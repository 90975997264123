import React from 'react'
import { FaBasketShopping } from 'react-icons/fa6'
import { TbHomeFilled } from 'react-icons/tb'
import { TiUser } from 'react-icons/ti'
import { Link } from 'react-router-dom'

const App_footer = () => {
  return (
    <div id='footer'>
      <Link><TbHomeFilled />
      <span>Home</span>
      </Link>
      <Link><FaBasketShopping />
      <span>Basket</span>
      </Link>
      <Link><TiUser /> 
      <span>Profile</span>
      </Link>
    </div>
  )
}

export default App_footer
