import React, { useState } from 'react'
import tomato from "../images/tomato.jpg"
import { FaBasketShopping, FaStar, FaPlus, FaMinus } from 'react-icons/fa6'
import { IoChevronBackSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const Product = () => {
  const [quantity, setQuantity] = useState(0);

  const increaseQuantity = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity(prevQuantity => prevQuantity > 0 ? prevQuantity - 1 : 0);
  };

  return (
    <div id='product-page'>
      <div className='product_img'>
      <div className='back-butn'><Link to="/"><IoChevronBackSharp /></Link></div>
        <img src={tomato} alt="Tomato" />
        <div className='prduct-basket'>
          <FaBasketShopping />
        </div>
      </div>
      <div className='container'>
        <div className='product-details'>
          <div className='section-title'>
            <h3>Tomato</h3>
          </div>
          <div className='product-top'>
          <div className='produt-rating'>
            <FaStar style={{color: '#1fff1f'}}/>
            <FaStar style={{color: '#1fff1f'}}/>
            <FaStar style={{color: '#1fff1f'}}/>
            <FaStar style={{color: '#1fff1f'}}/>
            <FaStar/>
            <span>4.5(10k)</span>
          </div>
          <div className='increment-product'>
            <button onClick={decreaseQuantity}><FaMinus /></button>
            <span>{quantity}</span>
            <button onClick={increaseQuantity}><FaPlus /></button>
          </div>
          </div>

          <div className='product_description'>
          
            <h3>Description</h3>
          
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p>
            
          </div>
        </div>
      </div>
      <div id='footer' >
      <div className='product-footer'>
        <span>Price</span>
        <div className='product-footer-price'>
            <h3>25₹</h3>
            <button>Add to cart</button>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Product